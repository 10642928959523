import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	CircularProgress,
	Paper,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// services
import Gupport from "../../services/gupport";

class UserTokens extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			tokens: [],
			selectedToken: null,
			testingToken: false,
			buttonDisable: true,
			openDialog: false,
			openTestTokenDialog: false,
			openDeleteTokenDialog: false,
			dialogActionText: null,
			message: null,
		};

		this.refresh = this.refresh.bind(this);
		this.fetchTokens = this.fetchTokens.bind(this);
		this.showTestDialog = this.showTestDialog.bind(this);
		this.showDeleteDialog = this.showDeleteDialog.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleTextChanged = this.handleTextChanged.bind(this);
		this.testToken = this.testToken.bind(this);
		this.deleteToken = this.deleteToken.bind(this);
	}

	componentDidMount() {
		this.fetchTokens(this.props.userId);
	}

	refresh() {
		this.fetchTokens(this.props.userId);
	}

	fetchTokens(userId) {
		const cmd = {
			action: "getTokens",
			username: userId,
		};
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					loading: false,
					error: null,
					tokens: msg.payload.data,
				});
			} else {
				this.setState({
					loading: false,
					error: msg.payload.data,
					tokens: [],
				});
			}
		});
	}

	showTestDialog(token) {
		this.setState({
			buttonDisable: true,
			openTestTokenDialog: true,
			selectedToken: token,
			message: null,
		});
	}

	showDeleteDialog(token) {
		this.setState({
			openDeleteTokenDialog: true,
			selectedToken: token,
		});
	}

	handleClose() {
		this.setState({
			openDialog: false,
			openTestTokenDialog: false,
			openDeleteTokenDialog: false,
			selectedToken: null,
			message: null,
		});
	}

	handleTextChanged(event) {
		if (event.target.value.trim()) {
			this.setState({
				buttonDisable: false,
				message: event.target.value,
			});
		} else {
			this.setState({
				buttonDisable: true,
			});
		}
	}

	testToken() {
		const { t } = this.props;
		if (this.state.selectedToken && this.state.message) {
			this.setState({
				testingToken: true,
			});
			const cmd = {
				action: "sendPush",
				pusher: this.state.selectedToken.pusher,
				token: this.state.selectedToken.token,
				message: this.state.message,
			};
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						openTestTokenDialog: false,
						testingToken: false,
					});
				} else {
					this.setState({
						openDialog: true,
						dialogActionText: t("users.sendPush"),
						openTestTokenDialog: false,
						testingToken: false,
					});
				}
			});
		}
	}

	deleteToken() {
		const { t } = this.props;
		if (this.state.selectedToken) {
			const cmd = {
				action: "deleteToken",
				token: this.state.selectedToken.token,
				username: this.props.userId,
			};
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						openDeleteTokenDialog: false,
					});
					this.fetchTokens(this.props.userId);
				} else {
					this.setState({
						openDialog: true,
						dialogActionText: t("users.deleteToken"),
						openDeleteTokenDialog: false,
					});
				}
			});
		}
	}

	render() {
		const { t } = this.props;
		if (this.state.loading) {
			return <CircularProgress />;
		}
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const columnStyle = {
			paddingRight: "5px",
			paddingLeft: "5px",
		};
		const attributeStyle = {
			whiteSpace: "normal",
			overflow: "inherit",
			wordBreak: "break-all",
			width: "90%",
			paddingRight: 0,
		};
		const isPushAvailable = Boolean(Gupport.sendPush);
		const isDeleteTokenAvailable = Boolean(Gupport.deleteToken);

		return (
			<Paper style={{ overflow: "hidden" }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell colSpan={3}>{t("users.agent")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(this.state.tokens.length === 0) ?
							<TableRow className="last-row-no-border">
								<TableCell colSpan={3}>{t("users.noTokens")}</TableCell>
							</TableRow>
							:
							this.state.tokens.map((token, index) => (
								<TableRow key={index} className="last-row-no-border">
									<TableCell style={attributeStyle}>
										<strong>{token.agent}</strong>
										<br />
										{token.token}
									</TableCell>
									{isPushAvailable &&
										<TableCell style={columnStyle}>
											<Button variant="contained" onClick={this.showTestDialog.bind(this, token)}>
												{t("users.test")}
											</Button>
										</TableCell>
									}
									{isDeleteTokenAvailable &&
										<TableCell style={columnStyle}>
											<Button variant="contained" onClick={this.showDeleteDialog.bind(this, token)}>
												{t("users.delete")}
											</Button>
										</TableCell>
									}
								</TableRow>
							))
						}
					</TableBody>
				</Table>
				<Dialog
					fullWidth={true}
					open={this.state.openDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("users.dialog.title")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("users.dialog.content", { text: this.state.dialogActionText })}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.openTestTokenDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("users.dialogSend")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("users.notificationMessage")}
							&nbsp;
							<TextField
								placeholder={t("users.messageHint")}
								fullWidth={true}
								onChange={this.handleTextChanged}
							/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							disabled={this.state.buttonDisable}
							loading={this.state.testingToken}
							onClick={this.testToken}
						>
							{t("users.send")}
						</LoadingButton>
						<Button color="inherit" onClick={this.handleClose}>{t("dialog.cancel")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.openDeleteTokenDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("users.dialogDelete")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("users.deleteConfirm")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="danger" onClick={this.deleteToken}>{t("dialog.delete")}</Button>
						<Button color="inherit" onClick={this.handleClose}>{t("dialog.cancel")}</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		);
	}

}

UserTokens.propTypes = {
	userId: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(UserTokens);
