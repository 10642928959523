import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { IconButton, CircularProgress } from "@mui/material";
// cmp
import withNavigateAndParams from "../cmp/withNavigateAndParams";
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import UserTabs from "../cmp/user/user-tabs";
import SearchAutocomplete from "../cmp/SearchAutocomplete";
import Svg from "../cmp/svg";
// services
import Constants from "../services/constants";
import Gupport from "../services/gupport";
import { Storage, StorageKeys } from "../services/storage";
import { muiTheme } from "@local/theme";
// types
import type { WithTranslation } from "react-i18next";
import type { CmdGetB2CUser, CmdGetUser } from "../types/gupport";
import type { UserId } from "../types/user";
import type { ReactRouterProps } from "../types/misc";

type Props = WithTranslation & ReactRouterProps & {
	separatePage?: boolean;
};

type State = {
	ready: boolean;
	userId: UserId | null;
	loading: boolean;
	error: any | null; // TODO
	advancedSearchEnabled: boolean;
	user: any | null; // TODO
};

class UsersPage extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		let userId;
		if (this.props.params.userId) {
			Storage.set(StorageKeys.userId, this.props.params.userId);
			userId = this.props.params.userId;
		} else if (Storage.get(StorageKeys.userId)) {
			userId = Storage.get(StorageKeys.userId);
		} else {
			userId = null;
		}

		this.state = {
			ready: Gupport.ready,
			userId: userId,
			loading: false,
			error: null,
			advancedSearchEnabled: false,
			user: null,
		};

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.handleToggleChange = this.handleToggleChange.bind(this);
		this.handleOpenNewWindow = this.handleOpenNewWindow.bind(this);
		this.handleItemSelected = this.handleItemSelected.bind(this);
		this.handleFetchUserResult = this.handleFetchUserResult.bind(this);
		this.handleCleared = this.handleCleared.bind(this);
	}

	override componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);

		if (Gupport.ready && this.state.userId && !this.state.loading) {
			this.fetchUser(this.state.userId);
		}
	}

	override componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.params.userId && this.props.params.userId !== prevState.userId) {
			const userId = this.props.params.userId;
			Storage.set(StorageKeys.userId, userId);
			this.setState({
				userId: userId,
			});
			if (Gupport.ready && userId && !this.state.loading) {
				this.fetchUser(userId);
			}
		}
	}

	override componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
	}

	handleToggleChange(isEnabled) {
		this.setState({
			advancedSearchEnabled: isEnabled,
		});
	}

	handleGupportReady() {
		this.setState({
			ready: Gupport.ready,
		});

		if (Gupport.ready && this.state.userId && !this.state.user) {
			this.fetchUser(this.state.userId);
		}
	}

	handleFetchUserResult(error, msg) {
		if (!error && msg?.payload.status === "ok") {
			this.setState({
				loading: false,
				error: error,
				user: msg.payload.data,
			});
		} else {
			this.setState({
				loading: false,
				error: error || msg.payload.data,
				user: null,
			});
		}
	}

	fetchUser(userId) {
		this.setState({
			loading: true,
		}, () => {
			if (this.state.advancedSearchEnabled) {
				const cmd = {
					action: "getB2CUser",
					sub: userId,
				} as const satisfies CmdGetB2CUser;
				Gupport.send(cmd, this.handleFetchUserResult);
			} else {
				const cmd = {
					action: "getUser",
					username: userId,
				} as const satisfies CmdGetUser;
				Gupport.send(cmd, this.handleFetchUserResult);
			}
		});
	}

	handleOpenNewWindow() {
		if (this.state.userId) {
			window.open(
				`user_desc.html#/users/${this.state.userId}/general`,
				"_blank"
			);
		}
	}

	handleItemSelected(item) {
		const userId = this.state.advancedSearchEnabled ? item.id : item.secondary;
		if (this.props.params.userId !== userId) {
			if (this.props.params.tab) {
				this.props.navigate(`/users/${userId}/${this.props.params.tab}`);
			} else {
				this.props.navigate(`/users/${userId}/general`);
			}
		}
	}

	handleCleared() {
		Storage.remove(StorageKeys.userId);
		Storage.remove("users/tab");

		this.setState({
			userId: null,
			loading: false,
			// error: null,
			user: null,
		}, () => {
			this.props.navigate("/users");
		});
	}

	renderPage() {
		const { t } = this.props;
		if (!this.state.ready) {
			return <CircularProgress />;
		}
		if (!this.state.userId) {
			return null;
		}
		if (this.state.error) {
			return <div>{t("users.errorMsg")}</div>;
		}
		if (this.state.loading || !this.state.user) {
			return <CircularProgress />;
		}

		return (
			<div>
				<UserTabs userId={this.state.userId} name={this.state.user.name} isAdvancedSearchEnabled={this.state.advancedSearchEnabled} />
			</div>
		);
	}

	override render() {
		const { t, separatePage } = this.props;
		const title = this.state.ready
			? (
				<SearchAutocomplete
					placeholder={t("users.searchHint")}
					value={this.state.user ? `${this.state.user.name ?? this.state.userId} (${this.state.userId})` : ""}
					kind={Constants.Kind.User}
					onItemSelected={this.handleItemSelected}
					onClear={this.handleCleared}
					onAdvanceSearchToggle={this.handleToggleChange}
				/>
			)
			: null;

		const iconElementRight = separatePage ? null : (
			<IconButton disabled={!this.state.user} onClick={this.handleOpenNewWindow}>
				<Svg src="navigation/openNewWindow.svg" color={this.state.user ? muiTheme.palette.primary.contrastText : "rgba(255,255,255,.7)"} />
			</IconButton>
		);

		return (
			<ResponsivePagePanel
				activeRoute="/users"
				title={title}
				iconElementRight={iconElementRight}
				hideNavigationDrawer={separatePage}
				hideNavigationMenu={separatePage}
			>
				{this.renderPage()}
			</ResponsivePagePanel>
		);
	}

}

UsersPage.defaultProps = {
	separatePage: false,
};

UsersPage.propTypes = {
	separatePage: PropTypes.bool,
	navigate: PropTypes.func.isRequired,
	params: PropTypes.shape({
		userId: PropTypes.string,
		tab: PropTypes.string,
	}).isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(withNavigateAndParams(UsersPage));
